import './utils/adaption'
import './App.scss'


function App() {
  let arr = [
    { img: require('./assets/imgs/0.jpg') },
    { img: require('./assets/imgs/1.png'), hrefid: 'index', href: 'https://detail.tmall.com/item.htm?spm=a1z10.1-b-s.w5003-24177506696.1.1fc54b1cpRfvPT&id=658934416328&rn=5165b0fbc28c1e3e2efab7bd9da2c1b8&abbucket=19&skuId=4928599142676&scene=taobao_shop' },
    { img: require('./assets/imgs/2.png'), hrefid: 'brandStory' },
    { img: require('./assets/imgs/3.png') },
    { img: require('./assets/imgs/4.png') },
    { img: require('./assets/imgs/5.png') },
    { img: require('./assets/imgs/6.png') },
    { img: require('./assets/imgs/7.png') },
    { img: require('./assets/imgs/8.png') },
    { img: require('./assets/imgs/9.png') },
    { img: require('./assets/imgs/10.png') },
    { img: require('./assets/imgs/11.png') },
    { img: require('./assets/imgs/12.png') },
    { img: require('./assets/imgs/13.png') },
    { img: require('./assets/imgs/14.png') },
    { img: require('./assets/imgs/15.png'), hrefid: 'scientificResearchStrength' },
    { img: require('./assets/imgs/16.png') },
    { img: require('./assets/imgs/17.png') },
    { img: require('./assets/imgs/18.png') },
    { img: require('./assets/imgs/19.png') },
    { img: require('./assets/imgs/20.png') },
    { img: require('./assets/imgs/21.png') },
    { img: require('./assets/imgs/22.png') },
    { img: require('./assets/imgs/23.png') },
    { img: require('./assets/imgs/24.png') },
    { img: require('./assets/imgs/25.png') },
    { img: require('./assets/imgs/26.png') },
    { img: require('./assets/imgs/27.png') },
    { img: require('./assets/imgs/28.png') },
    { img: require('./assets/imgs/29.png') },
    { img: require('./assets/imgs/30.png') },
    { img: require('./assets/imgs/31.png') },
    { img: require('./assets/imgs/32.png') },
    { img: require('./assets/imgs/33.png') },
    { img: require('./assets/imgs/34.png') },
    { img: require('./assets/imgs/35.png') },
    { img: require('./assets/imgs/36.png') },
    { img: require('./assets/imgs/37.png') },
    { img: require('./assets/imgs/38.png') },
    { img: require('./assets/imgs/39.png') },

    { img: require('./assets/imgs/40.JPG') },
    { img: require('./assets/imgs/41.JPG') },
    { img: require('./assets/imgs/42.JPG') },
    { img: require('./assets/imgs/43.JPG') },
    { img: require('./assets/imgs/44.JPG') },
    { img: require('./assets/imgs/45.png') },
    { img: require('./assets/imgs/46.png') },
    { img: require('./assets/imgs/47.png') },
    { img: require('./assets/imgs/logotittle.png') },
  ]

  return (
    <div className="App">
      <div className="header">
        <img src={require('./assets/imgs/logotittle.png')} />
      </div>

      <div className="navigation">
        <a href='#index'>首页</a>
        <a href="#brandStory">产品故事</a>
        <a href="#scientificResearchStrength">科研实力</a>
      </div>

      <div className="img_list">
        {
          arr.map((i, v) => {
            return <img className="img_item" id={i.hrefid ? i.hrefid : ''} key={v} src={i.img} alt="" onClick={() => i.href ? window.location.href = i.href : ''} style={{ cursor: 'pointer' }} />
          })
        }
      </div>


      <div className="footer">
        <div>
          <p>中草汉芳生物科技（广州）有限公司</p>
          <p>公司地址：广州市白云区永平街元下田三路31号101铺</p>
          <p onClick={() => { console.log(window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index') }}>粤ICP备2021054334号</p>
        </div>
      </div>

      <div className="position" onClick={() => { window.location.href = 'https://detail.tmall.com/item.htm?spm=a1z10.1-b-s.w5003-24177506696.1.1fc54b1cpRfvPT&id=658934416328&rn=5165b0fbc28c1e3e2efab7bd9da2c1b8&abbucket=19&skuId=4928599142676&scene=taobao_shop' }}>
        <img src={require('./assets/imgs/buy.png')} alt="" />
      </div>
    </div>
  );
}

export default App;
